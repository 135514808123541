import "../Sidebar/scss/single-side-bar.module.scss";
import "../ContentWrapper/scss/app.module.scss";
import "./scss/exhibitor-virtual-booth.scss";

import React, { useEffect } from "react";

import { ExhibitorBooth } from "./ExhibitorBooth";
import ExhibitorBoothStyles from "./ExhibitorBoothStyles";
import breadcrumbsStyles from "../scss/breadcrumbs.module.scss";
import { connect } from "react-redux";
import exhibitorContactStyles from "./scss/exhibitor-contacts.module.scss";
import exhibitorDocumentStyles from "./scss/exhibitor-documents.module.scss";
import headerStyles from "../Header/scss/desktop.module.scss";
import headerWrapperStyles from "../Header/scss/header-wrapper.module.scss";
import sharedStyles from "../Header/scss/shared.module.scss";
import sideBarStyles from "../Sidebar/scss/single-side-bar.module.scss";
import singleExhibitorStyles from "./scss/single-exhibitor.module.scss";
import socialLinkStyles from "../SocialLinks/scss/social-links.module.scss";
import { useParams } from "react-router-dom";

const VirtualBoothPreview = ({ virtualBooths }) => {
  const [booth, setBooth] = React.useState(null);
  const [exhibitorTheme, setExhibitorTheme] = React.useState(null);
  const { exhibitorID } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (virtualBooths) {
      if (virtualBooths && virtualBooths.length > 0) {
        let theme = null;
        let booth = virtualBooths.find((exhibitor) => {
          console.log(exhibitor.client_id);
          console.log(exhibitorID);
          return exhibitorID === exhibitor.client_id;
        });
        if (booth && booth.colors.bg_color) {
          if (booth.colors.bg_color === "Dark Mode") {
            theme = "single-exhibitor-dark-theme";
          } else if (booth.colors.bg_color === "Light Mode") {
            theme = "single-exhibitor-light-theme";
          }
          document.body.classList.add(theme);
        }

        if (booth) {
          document.head.insertAdjacentHTML(
            "beforeend",
            ExhibitorBoothStyles({ booth })
          );
        }
        setExhibitorTheme(theme);
        setBooth(booth);
      }
    }
  }, [virtualBooths, exhibitorID, setExhibitorTheme]);
  return (
    booth && (
      <section>
        <div
          className={`${headerWrapperStyles.header} ${headerWrapperStyles.preEvent}`}
          role="banner"
        >
          <a href="#content" className="sr-focusable">
            Skip to main content
          </a>
          <div
            className={`${sharedStyles.headerLogo} ${sharedStyles.preEvent}`}
          >
            <div className={headerStyles.headerInterior}>
              <h1>
                <a className={`${sharedStyles.headerLogo}`} to="#">
                  <img
                    src="/images/logo.png"
                    alt="NADA 2020"
                    role="presentation"
                    aria-hidden="true"
                  />
                </a>
              </h1>
              <nav
                className={headerStyles.headerInteriorRight}
                aria-label="Primary"
                role="navigation"
              ></nav>
            </div>
          </div>
        </div>
        <div>
          <div className={singleExhibitorStyles.main}>
            <nav
              aria-label="Breadcrumbs"
              className={breadcrumbsStyles.breadcrumbsContainer}
            >
              <ol>
                <li>
                  <a className={breadcrumbsStyles.backButton} href="#">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      data-svg="chevron-left"
                    >
                      <polyline
                        fill="none"
                        stroke="#00a0df"
                        strokeWidth="1.03"
                        points="13 16 7 10 13 4"
                      ></polyline>
                    </svg>
                    <span>Exhibitors</span>
                  </a>
                </li>
              </ol>
            </nav>
            {booth ? <ExhibitorBooth booth={booth} exhibitor={booth} /> : ""}
            <div className={singleExhibitorStyles.singleSpeakerContainer}>
              <section className={`${sideBarStyles.sideBar}`}>
                <a
                  href="#"
                  className="logo-website-link"
                  rel="noopener noreferrer"
                >
                  <div className="company-image company-image-large">
                    <div className="sr-only">
                      Vaughan Company, Inc. - #1 in Chopper Pumps
                    </div>
                  </div>
                </a>
                <div className="single-list-details left-details">
                  <h2 className="details-title">Location</h2>
                  <ul>
                    <li>United Kingdom</li>
                    <li>Ireland</li>
                    <li>United States</li>
                    <li>India</li>
                  </ul>
                  <h2 className="details-title">Product Categories</h2>
                  <ul className="exhibitor-product-categories">
                    <li>Category 1</li>
                    <li>Category 2</li>
                    <li>Category 3</li>
                    <li>Category 4</li>
                  </ul>
                </div>
              </section>
              <section className={singleExhibitorStyles.content}>
                <h1>EXAMPLE</h1>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
                  quia voluptas sit aspernatur aut odit aut fugit, sed quia
                  consequuntur magni dolores eos qui ratione voluptatem sequi
                  nesciunt. Neque porro quisquam est, qui dolorem ipsum quia
                  dolor sit amet, consectetur, adipisci velit, sed quia non
                  numquam eius modi tempora incidunt ut labore et dolore magnam
                  aliquam quaerat voluptatem.
                </p>
                <a
                  href="https://www.website.com/"
                  className={`${singleExhibitorStyles.singleWebsite} gtm-exhibitor-website`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>https://www.website.com/</span>
                </a>
                <nav
                  className={singleExhibitorStyles.exhibitorSocialLinks}
                  aria-label={`Social Media`}
                >
                  <ul className={socialLinkStyles.linkList}>
                    <ul className={socialLinkStyles.linkList}>
                      <li className={socialLinkStyles.linkListItem}>
                        <a href="#" className="gtm-speaker-social">
                          <img
                            alt={`Facebook logo`}
                            src={`/images/social-icons/facebook-white.svg`}
                          />
                        </a>
                      </li>
                      <li className={socialLinkStyles.linkListItem}>
                        <a href="#" className="gtm-speaker-social">
                          <img
                            alt={`Twitter logo`}
                            src={`/images/social-icons/twitter-white.svg`}
                          />
                        </a>
                      </li>
                      <li className={socialLinkStyles.linkListItem}>
                        <a href="#" className="gtm-speaker-social">
                          <img
                            alt={`Linkedin logo`}
                            src={`/images/social-icons/linkedin-white.svg`}
                          />
                        </a>
                      </li>
                      <li className={socialLinkStyles.linkListItem}>
                        <a href="#" className="gtm-speaker-social">
                          <img
                            alt={`Instagram logo`}
                            src={`/images/social-icons/instagram-white.svg`}
                          />
                        </a>
                      </li>
                      <li className={socialLinkStyles.linkListItem}>
                        <a href="#" className="gtm-speaker-social">
                          <img
                            alt={`Snapchat logo`}
                            src={`/images/social-icons/snapchat-white.svg`}
                          />
                        </a>
                      </li>
                      <li className={socialLinkStyles.linkListItem}>
                        <a href="#" className="gtm-speaker-social">
                          <img
                            alt={`Youtube logo`}
                            src={`/images/social-icons/youtube-white.svg`}
                          />
                        </a>
                      </li>
                    </ul>
                  </ul>
                </nav>
                <div
                  className={`${singleExhibitorStyles.contentWrapperWithContact} content-wrapper-with-contact`}
                >
                  <div className={singleExhibitorStyles.leftDetails}>
                    <h4>PRODUCT LINKS AND DOWNLOADS</h4>
                    <ul>
                      <li>
                        <a
                          href="#"
                          id="exhibitor-document-1"
                          className={`${exhibitorDocumentStyles.pdfLinks} gtm-exhibitor-resource-link`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            role="img"
                            style={{ flexShrink: 0 }}
                          >
                            <g
                              id="Group_57"
                              data-name="Group 57"
                              transform="translate(-505 -919)"
                            >
                              <circle
                                id="Ellipse_3"
                                cx="16"
                                cy="16"
                                r="16"
                                fill="#002e6d"
                                data-name="Ellipse 3"
                                transform="translate(505 919)"
                                role="presentation"
                              ></circle>
                              <path
                                id="Path_76"
                                fill="#fff"
                                d="M6.75 0v2.25h7.409L0 16.409 1.591 18 15.75 3.841v7.409H18V0z"
                                data-name="Path 76"
                                transform="translate(512 926)"
                                role="presentation"
                              ></path>
                            </g>
                          </svg>
                          <p className={exhibitorDocumentStyles.linkTitle}>
                            Product Document 1
                          </p>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          id="exhibitor-document-2"
                          className={`${exhibitorDocumentStyles.pdfLinks} gtm-exhibitor-resource-link`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            role="img"
                            style={{ flexShrink: 0 }}
                          >
                            <g
                              id="Group_57"
                              data-name="Group 57"
                              transform="translate(-505 -919)"
                            >
                              <circle
                                id="Ellipse_3"
                                cx="16"
                                cy="16"
                                r="16"
                                fill="#002e6d"
                                data-name="Ellipse 3"
                                transform="translate(505 919)"
                                role="presentation"
                              ></circle>
                              <path
                                id="Path_76"
                                fill="#fff"
                                d="M6.75 0v2.25h7.409L0 16.409 1.591 18 15.75 3.841v7.409H18V0z"
                                data-name="Path 76"
                                transform="translate(512 926)"
                                role="presentation"
                              ></path>
                            </g>
                          </svg>
                          <p className={exhibitorDocumentStyles.linkTitle}>
                            Product Document 2
                          </p>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          id="exhibitor-document-3"
                          className={`${exhibitorDocumentStyles.pdfLinks} gtm-exhibitor-resource-link`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            role="img"
                            style={{ flexShrink: 0 }}
                          >
                            <g
                              id="Group_57"
                              data-name="Group 57"
                              transform="translate(-505 -919)"
                            >
                              <circle
                                id="Ellipse_3"
                                cx="16"
                                cy="16"
                                r="16"
                                fill="#002e6d"
                                data-name="Ellipse 3"
                                transform="translate(505 919)"
                                role="presentation"
                              ></circle>
                              <path
                                id="Path_76"
                                fill="#fff"
                                d="M6.75 0v2.25h7.409L0 16.409 1.591 18 15.75 3.841v7.409H18V0z"
                                data-name="Path 76"
                                transform="translate(512 926)"
                                role="presentation"
                              ></path>
                            </g>
                          </svg>
                          <p className={exhibitorDocumentStyles.linkTitle}>
                            Product Document 3
                          </p>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          id="exhibitor-document-4"
                          className={`${exhibitorDocumentStyles.pdfLinks} gtm-exhibitor-resource-link`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            role="img"
                            style={{ flexShrink: 0 }}
                          >
                            <g
                              id="Group_57"
                              data-name="Group 57"
                              transform="translate(-505 -919)"
                            >
                              <circle
                                id="Ellipse_3"
                                cx="16"
                                cy="16"
                                r="16"
                                fill="#002e6d"
                                data-name="Ellipse 3"
                                transform="translate(505 919)"
                                role="presentation"
                              ></circle>
                              <path
                                id="Path_76"
                                fill="#fff"
                                d="M6.75 0v2.25h7.409L0 16.409 1.591 18 15.75 3.841v7.409H18V0z"
                                data-name="Path 76"
                                transform="translate(512 926)"
                                role="presentation"
                              ></path>
                            </g>
                          </svg>
                          <p className={exhibitorDocumentStyles.linkTitle}>
                            Product Document 4
                          </p>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          id="exhibitor-document-5"
                          className={`${exhibitorDocumentStyles.pdfLinks} gtm-exhibitor-resource-link`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            role="img"
                            style={{ flexShrink: 0 }}
                          >
                            <g
                              id="Group_57"
                              data-name="Group 57"
                              transform="translate(-505 -919)"
                            >
                              <circle
                                id="Ellipse_3"
                                cx="16"
                                cy="16"
                                r="16"
                                fill="#002e6d"
                                data-name="Ellipse 3"
                                transform="translate(505 919)"
                                role="presentation"
                              ></circle>
                              <path
                                id="Path_76"
                                fill="#fff"
                                d="M6.75 0v2.25h7.409L0 16.409 1.591 18 15.75 3.841v7.409H18V0z"
                                data-name="Path 76"
                                transform="translate(512 926)"
                                role="presentation"
                              ></path>
                            </g>
                          </svg>
                          <p className={exhibitorDocumentStyles.linkTitle}>
                            Product Document 5
                          </p>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className={singleExhibitorStyles.singleListDetails}>
                    <div
                      className={exhibitorContactStyles.representativeWrapper}
                    >
                      <h2>Representative Contact</h2>
                      <ul className={singleExhibitorStyles.contactContainer}>
                        <li className="exhibitor-contact-name">
                          FirstName LasName
                        </li>
                        <li>Co-Founder, CEO</li>
                        <li className="exhibitor-contact-phone">
                          +1-123-123-2312{" "}
                        </li>
                        <li className="exhibitor-contact-email">
                          <a href="#" className="gtm-exhibitor-contact">
                            <span>firstlastname@email.io</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div
                      className={singleExhibitorStyles.scheduleMeetingContainer}
                    >
                      <a
                        href="#"
                        className={`${singleExhibitorStyles.detailsButton} gtm-schedule-meeting`}
                        rel="noopener noreferrer"
                        color="initial"
                      >
                        <span>Schedule a Meeting</span>
                      </a>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    )
  );
};
const mapStateToProps = (state /*, ownProps*/) => {
  return {
    virtualBooths: state.global.virtualBooths,
  };
};

export default connect(mapStateToProps)(VirtualBoothPreview);
